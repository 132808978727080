@import "../../scss/typography";
@import "../../scss/variables";
@import "../../scss/mixin";

footer {
    background-color:$primary;;
    padding: 60px 0px 48px 0px;
    border-top:1px solid $white;
    margin-top:auto;
    section.footer {
        .content_wrap {

            .footer_grid {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                gap: 20px;
                @media only screen and (max-width:$laptop){
                    grid-template-columns: 1fr 1fr 1fr;
                }
                @media only screen and (max-width:$tab){
                    grid-template-columns: 1fr 1fr;
                    gap:30px;
                }
                @media only screen and (max-width:$mobile){
                    grid-template-columns: 1fr;
                }

                .company_info {
                    .img_wrap {
                        width:150px;

                        img {
                            width: 100%;
                        }
                    }

                    h3 {
                        margin-top: 30px;
                        color: $white;
                    }

                    .input_wrap {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        gap: 10px;
                        margin: 15px 0px;

                        input {
                            @include font('GildaDisplay-Regular', 16px, 20px, 600, $white);
                            padding: 15px 20px;
                            border-radius: 1000px;
                            background-color: transparent;
                            border: 1px solid $white;
                            letter-spacing: 1px;
                            width: calc(100% - 100px);

                            &::placeholder {
                                @include font('GildaDisplay-Regular', 16px, 20px, 600, $white);
                                text-transform: capitalize;
                                letter-spacing: 1px;
                            }

                            &:focus{
                                outline: none;
                            }

                        }

                        button {
                            border-radius: 1000px;
                            border: 1px solid transparent;
                            padding: 15px 20px;
                            background-color: transparent;
                            @include font('GildaDisplay-Regular', 16px, 20px, 600, $white);
                            transition: all 0.3s ease-in-out;

                            &:hover {
                                border-color: $white;
                            }
                        }
                    }
                }

                .link_col {
                    display: flex;
                    flex-direction: column;
                    gap: 12px;

 @media only screen and (max-width:$mobile){
    gap:8px;
 }
                    

                    .social_wrap{
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        gap:8px;
                        margin-top:20px;
                        a{
                            height:35px;
                            width:35px;
                            border-radius: 50%;
                            background-color: $white;
                            display: grid;
                            place-items: center;
                         
                            transition:all 0.3s ease-in-out;
                            svg{
                                color:$primary;
                                font-size:18px;
                                transition:all 0.3s ease-in-out;
                            }
                            &:hover{
                                background-color: $primary;
                                svg{
                                    color:$white;
                                };
                            }
                        }
                    }

                    p.title {
                        @include font('GildaDisplay-Regular', 20px, 24px, 600, $white);
                        text-transform: uppercase;
                        letter-spacing: 1px;
                    }

                    a {
                        display: block;
                        text-decoration: none;
                        @include font('GildaDisplay-Regular', 20px, 24px, 400, $white);
                        letter-spacing: 1px;
                        transition:all 0.3s ease-in-out;
                        &:hover, &.active{
                            color:$secondary;
                        }
                        @media only screen and (max-width:$mobile){
                            @include font('GildaDisplay-Regular', 18px, 22px, 400, $white);
                        }
                    }
                }
            }
       
            .bottom_line{
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap:15px;
                margin-top:30px;
                padding-top:25px;
                border-top: 1px solid $white;
                p{
                    color:$white;
                }
              
                .right{
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    gap:24px;
                    a {
                        display: block;
                        text-decoration: none;
                        @include font('GildaDisplay-Regular', 20px, 24px, 400, $white);
                        letter-spacing: 1px;
                        transition:all 0.3s ease-in-out;
                        &:hover, &.active{
                            color:$secondary;
                        }
                    }
                }
            }
        }
    }
}