@import "../../scss/typography";
@import "../../scss/variables";


section.faq {
    background-color: $bgLight;

    .content_wrap {
        display: grid;
        grid-template-columns:0.7fr 1fr;
        gap: 40px;
        @media only screen and (max-width:$laptop){
            grid-template-columns:0.5fr 1fr;
        }
        @media only screen and (max-width:$tab){
            grid-template-columns:1fr;
        }
        .category_side{
            .tab_wraper{
                margin-top:50px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap:1px;
                button{
                    padding:12px 15px;
                    @include font('SctoGroteskA-Regular', 16px, 20px, 600 , $primary);
                    min-width:150px;
                    background-color: $white;
                    border-style: none;
                    transition:all 0.3s ease-in-out;
                    &:nth-child(1){
                        border-radius: 8px 8px 0px 0px;
                    }
                    &:nth-child(3){
                        border-radius:0px 0px 8px 8px;
                    }
                    &.active, &:hover{
                        background-color: $primary;
                        color:$white;
                    }
                }
                @media only screen and (max-width:$tab){
                    flex-direction:row;
                    margin-top:20px;
                    button{
                        &:nth-child(1){
                            border-radius: 8px 0px 0px 8px;
                        }
                        &:nth-child(3){
                            border-radius:0px 8px 8px 0px;
                        }
                    }
                }
                @media only screen and (max-width:$mobile){
                    flex-direction:column;
                    button{
                        &:nth-child(1){
                            border-radius: 8px 8px 0px 0px;
                        }
                        &:nth-child(3){
                            border-radius:0px 0px 8px 8px;
                        }
                    }
                }
            }
        }

        .accordion_side {
            .accordion {
                background-color: transparent;
                border-style: none;
                .accordion-item {
                    background-color: transparent;
                    border-style: none;

                    .accordion-header {
                        background-color: transparent;

                        button {
                            padding: 15px 0px;
                            background-color: transparent;
                            border-bottom: 1px solid rgba($primary, 0.3);
                            @include font('SpaceGrotesk-Medium', 26px, 30px, 400, $primary);

                            &:focus {
                                outline: none;
                                box-shadow: none
                            }
                        }
                    }

                    .accordion-collapse {
                        .accordion-body {
                            padding: 15px 0px;
                            p{
                                margin-bottom:10px;
                            }

                        }
                    }
                }
            }
        }

    }
}