@import "./scss/typography";

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  scroll-behavior: unset !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

section {
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;

  @media only screen and (max-width:$laptop) {
    padding-left: 30px;
    padding-right: 30px;
  }

  @media only screen and (max-width:$tab) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media only screen and (max-width:$mobile) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.content_wrap {
  max-width: 1320px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.p_t {
  padding-top: 120px;

  @media only screen and (max-width:$laptop) {
    padding-top: 100px;
  }

  @media only screen and (max-width:$tab) {
    padding-top: 80px;
  }

}

.p_b {
  padding-bottom: 120px;

  @media only screen and (max-width:$laptop) {
    padding-bottom: 100px;
  }

  @media only screen and (max-width:$tab) {
    padding-bottom: 80px;
  }
}


.cookie_model {
  .modal_body {
    padding: 0px !important;
  }
}

.center_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 48px;
}

.header_filler {
  margin-top: 86px;

  @media only screen and (max-width:$mobile) {
    margin-top: 72px;
  }
}

.CookieConsent {
  background-color: $primary;
  display:flex;
  align-items: center !important;
  padding:48px!important;
  @media only screen and (max-width:$mobile){
    padding:16px 16px 32px 16px!important;
  }

  button#rcc-decline-button {
    background-color: $secondary !important;
    color: $white !important;
    font-size: 16px !important;
    border-radius: 6px !important;
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: $white !important;
      color: $primary !important;
    }
  }

  button#rcc-confirm-button {
    background-color: $secondary !important;
    color: $white !important;
    font-size: 16px !important;
    border-radius: 6px !important;
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: $white !important;
      color: $primary !important;
    }

  }
}

section.policy_page_landing {
  .content_wrap {
    position: relative;

    button.close {
      position: absolute;
      top: 0px;
      right: 16px;
      border: 1px solid #ffffff;
      padding: 8px;
      border-radius: 8px;

      svg {
        font-size: 18px;
        color: $white;
      }
    }
  }
}

section.policy_page_content {
  position: relative;


  h4 {
    margin-top: 16px;
  }

  p {
    font-size: 16px;

  }
}