@import "../../../scss/typography";
@import "../../../scss/variables";

section.why_choose_title {
    background-color: $primary;

    .content_wrap {
        display: flex;
        flex-direction: column;
        align-items: center;

        h2{
            text-align: center;
        }
        p{
            text-align: center;
            color:$white;
            margin-top:10px;
        }
    }

    .slide_wraper{
        margin-top:50px;
        button.slick-arrow{
            display:none!important;
        }
        .card_wraper{
            padding:15px;
            @media only screen and (max-width:$mobile){
                padding:10px;
            }
            @media only screen and (max-width:550px){
                padding:0px;
            }
            .card{
                background-color: $primary;
                padding:30px;
                border:1px solid $secondary;
                @media only screen and (max-width:$mobile){
                    padding:20px;
                }
                svg{
                    color:$secondary;
                    font-size:32px;
                }
                h3{
                    color:$white;
                    margin:10px 0px;
                }
                p{
                    color:$white;
                }
            }
        }
    }
}