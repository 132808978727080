@import "../../../scss/typography";
@import "../../../scss/variables";

section.left_info{
    .content_wrap{
        ul{
            margin-top:15px;
        }
        img{
            width:100%;
        }
    }
}