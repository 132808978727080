@import "../../scss/typography";
@import "../../scss/variables";

section.policy_page_landing{
    background-color: $primary;
    padding-top:48px;
    padding-bottom:48px;
    position:relative;
    padding-top:100px;
    button.close{
        border:1px solid #ffffff;
        position:absolute;
        top:0px;
        right:0px;
        z-index:99;
        color:$white;
        padding:8px;
        background:$secondary;
        color:$primary;
    }
}

section.policy_page_content {
    .content_wrap {
h3{
    text-align: center;
    margin:16px 0px;
}
        .tri_sec{
            display: grid;
            grid-template-columns:1fr 1fr 1fr;
            gap:20px; 

            @media only screen and (max-width:$laptop){
                grid-template-columns:1fr 1fr;
            }
            @media only screen and (max-width:$tab){
                grid-template-columns:1fr;
            }
            p.t-20{
                margin-bottom:10px;
                font-weight:600;
            }
            p.text{
                margin:0px;                
                border-bottom:1px solid $primary;
                padding:15px 0px;
                @media only screen and (max-width:$tab){
                    padding:10px 0px;
                }
                &.b_t{
                    border-top:1px solid $primary;
                }
            }
        }

        p {
            @include font('GildaDisplay-Regular', 18px, 24px, 500, $primary);
        }

        h2.gradient {
            font-size: 40px;
            line-height: 48px;
            margin-top: 30px;

            @media only screen and (max-width:$tab) {
                font-size: 32px;
                line-height: 40px;
            }
        }

        p {
            margin-top: 15px;
        }

        ul {
            color: $primary;
            margin-top: 15px;
            padding-left: 25px;

            li {
                p {
                    margin: 0px;
                }
            }
        }
    }
}