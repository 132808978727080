@import "../../scss/typography";
@import "../../scss/variables";
@import "../../scss/mixin";

header {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    background-color: $primary;
    padding: 10px 0px;
    z-index: 999999;
    min-height:72px;
    display: flex;
    justify-content: stretch;
    align-items: center;
    height:100px;
    @media only screen and (max-width:$mobile){
        height:72px;
      }
    section.top_header {
        .content_wrap {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .logo_wrap {
                img {
                    object-fit: contain;                    
                    width:160px;                    
                }
            }

            nav {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 50px;
                text-decoration: none;

                @media only screen and (max-width:1100px) {
                    gap: 20px;
                }

                @media only screen and (max-width:850px) {
                    display: none;
                }

                a {
                    @include font('GildaDisplay-Regular', 18px, 22px, 600, $white);
                    text-transform: uppercase;
                    text-decoration: none;
                    letter-spacing: 1px;
                    transition: all 0.3s ease-in-out;
                    position:relative;
                    cursor:pointer;
                    &::after{
                        content:"";
                        position:absolute;
                        bottom:-5px;
                        left:50%;
                        transform: translateX(-50%);
                        height:3px;
                        width:0px;
                        border-radius:1000px;
                        background-color: $secondary;
                        transition: all 0.3s ease-in-out;
                    }

                    &:hover, &.active {
                        color: $secondary;
                        &::after{
                            width:10px;
                        }
                    }
                }
                button.pop_over{
                    border-style: none;
                    background-color: transparent;
                    @include font('GildaDisplay-Regular', 18px, 22px, 600, $white);
                    text-transform: uppercase;
                    text-decoration: none;
                    letter-spacing: 1px;
                    transition: all 0.3s ease-in-out;
                    position:relative;
                    height:80px;
                    &.active{
                        color:$secondary;
                    }

                    .sub_menu{
                        border-radius: 4px;
                        position:absolute;
                        top:80px;
                        left:50%;
                        background-color: $white;
                        width:320px;
                        display: flex;
                        flex-direction: column;
                        transform:translateX(-50%) scaleY(0);
                        transition:all 0.2s ease-in-out;
                        transform-origin: top center;
                        box-shadow:1px 1px 5px 5px rgba(0, 0, 0, 0.1);
                        overflow: hidden;
                        
                        
                        a{
                            display:block;
                            padding:15px;
                            color:$primary;
                            text-align: left;
                            transition:all 0.3s ease-in-out;
                            &::after{
                                display: none;
                            }
                            &::before{
                                display: none;
                            }
                            &:hover, &.active{
                                background-color:$secondary;
                                color:$white;
                            }
                            
                        }
                    }
                    &:hover{
                        color:$secondary;
                        .sub_menu{
                            transform:translateX(-50%) scaleY(1);
                        }
                    }
                }
            }

            .right {
                display: flex;
                justify-content: flex-end;
                align-items: center;
            }

            button.menu {
                border-style: none;
                background-color: transparent;
                display: none;

                svg {
                    color: $white;
                    font-size: 25px;
                }

                @media only screen and (max-width:850px) {
                    display: block;
                }
            }

            button.primary_btn {
                @media only screen and (max-width:850px) {
                    display: none;
                }
            }
        }
    }

    .drower {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height:100vh;
        transition: all 0.3s ease-in-out;
        overflow: hidden;
        transform-origin: top center;
        background-color: $primary;
        display: flex;
        flex-direction: column;
        align-items: center;

        img{
            width:180px;
            margin:20px 0px;

        }


        &.open {
            transform: scaleY(1);
        }

        &.close {
            transform: scaleY(0);
        }

        nav{
            margin-top:100px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap:15px;
            a, button{
                transition:all 0.3s ease-in-out;
                @include font('GildaDisplay-Regular', 18px, 27px, 600, $white);
                letter-spacing: 1px;
                text-decoration:none;
                position:relative;
                &::after{
                    height:3px;width:20px;
                    border-radius: 1000px;
                    position:absolute;
                    content:"";
                    bottom:-3px;
                    height:3px;
                    width:0px;
                    left:50%;
                    transform: translateX(-50%);
                    background-color: $secondary;
                }
                &:hover, &.active{
                    color:$secondary;
                    &::after{
                        width:10px;
                    }
                }
            }
            button{
                border-style:none;
                background-color: transparent;
                &:focus{
                    outline: none;
                    box-shadow:none;
                }
            }
   
        }
        .solution_wrap{
            position:relative;
            p{
                @include font('GildaDisplay-Regular', 18px, 27px, 600, $white);
                &.active{
                    color:$secondary;
                }
            }
            .sub_nav{
                position:absolute;
                top:30px;
                left:50%;                
                transform:translateX(-50%) scaleY(0);
                transform-origin: top;
                transition:all 0.3s ease-in-out; 
                background-color: $white;
                border-radius: 8px;
                overflow: hidden;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                z-index:99;
                   a{
                    padding:5px 10px;
                    @include font('GildaDisplay-Regular', 18px, 27px, 600, $primary);
                    transition:all 0.3s ease-in-out;
                    display: block;
                    text-align: left;
                    &::after{
                        display: none;
                    }
                    &::before{
                        display: none;
                    }
                    &:hover, &.active{
                        color:$secondary;
                        text-align: left;
                    }
                   }           
            }
            &:hover{
                .sub_nav{
                    transform:translateX(-50%) scaleY(1);
                }
            }
        }
        .close{
            border-style: none;
            background-color: transparent;
            position:absolute;
            top:20px;
            right:20px;
            svg{
                font-size:25px;
                color:$white;
            }
        }
    }
}