@import "../../../scss/typography";
@import "../../../scss/variables";

section.counters_sec{
    background-color:$white;
    
    .content_wrap{
        h2{
            text-align: center;
        }   
        h3{
            text-align: center!important;
        
        }


        .counters_grid{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap:0px;
            border-radius: 10px;
            padding:60px;
            gap:80px;
            margin-top:32px;
            background-color:$primary;
            @media only screen and (max-width:$desktop){
                gap:30px;
            }
            @media only screen and (max-width:$tab){
                margin-top:60px;
                grid-template-columns: 1fr;
                padding:20px;
            }
            @media only screen and (max-width:$mobile){
                margin-top:40px;
            }
            .counter{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap:15px;
                position:relative;
                padding:0px 30px;
                &.m{
                    border-left:1px solid $white; 
                    border-right:1px solid $white; 
                 }
                @media only screen and (max-width:$laptop){
                    padding:0px 20px;   
                }
                @media only screen and (max-width:$tab){
                    padding:0px 0px;   
                    &.m{
                        padding:20px 0px;   
                        border-left:none;
                        border-right:none;
                       border-top:1px solid $white; 
                       border-bottom:1px solid $white; 
                    }
                }

p{
    color:$white;
}

                .left_l{
                    position:absolute;
                    left:-40px;
                    top:0px;
                    height:100%;
                    width:1px;
                    background-color: rgba($primary, 0.3);
                    @media only screen and (max-width:$desktop){
                        left:-15px;
                    }
                    @media only screen and (max-width:$tab){
                        display: none;
                    }
                }
                .right_l{
                    position:absolute;
                    right:-40px;
                    top:0px;
                    height:100%;
                    width:1px;
                    background-color: rgba($primary, 0.3);
                    @media only screen and (max-width:$desktop){
                        right:-15px;
                    }
                    @media only screen and (max-width:$tab){
                        display: none;
                    }
                }
            }
        }

        &.lrb{
            border-left:3px solid rgba($primary, 0.5);
            border-right:3px solid rgba($primary, 0.5);
            border-bottom:3px solid rgba($primary, 0.5);
            padding-left:24px;
            padding-right:24px;
            padding-bottom:24px;
            border-radius:0px 0px 24px 24px;
        }
    }
}