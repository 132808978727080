@import "../../../scss/typography";
@import "../../../scss/variables";

section.pov_sec{
    .content_wrap{
        .tri_grid{
            margin-top:80px;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap:30px;

            @media only screen and (max-width:$tab){
                margin-top:60px;
                grid-template-columns:1fr;
            }

            .pov_card{
                .img_wrap{
                    display: grid;
                    place-items:center;
                    background-color: $bgLight;
                    border-radius: 20px;
                    img{
                        max-width:100%;                        
                    }
                }
                h3{
                    margin-top:20px;
                }

                button{
                    border-style: none;
                    background-color: transparent;
                    margin-top:40px;
                    transition:all 0.3s ease-in-out;
                    @include font('SctoGroteskA-Regular', 14px, 18px, 600, $secondary);
                    &:hover{
                        color:$primary;
                    }
                }
            }
        }
    }
}