@import "../../../scss/typography";
@import "../../../scss/variables";


section.home_landing {
    min-height: 100vh;
    padding: 0px;
    position: relative;
    z-index: 0;
    overflow: hidden;

    .slide_wrap_box {
        .slick-slider {
            position: relative;
            z-index: 9999;

            .slick-list {
                .slick-slide {
                    .slide_wrap {
                        width: 100%;
                        height: 100%;
                        position: relative;
                        margin: 0px;
                        padding: 0px;

                        img {
                            height:calc(100vh - 80px);
                            width: 100%;
                            position: relative;
                            z-index: 0;
                            object-fit: cover;
                            @media only screen and (max-width:$mobile){
                                height:calc(100vh - 68px);
                            }
                        }

                        .overlay {
                            position: absolute;
                            bottom: 0px;
                            left: 0px;
                            width: 100%;
                            height: 100vh;
                            z-index: 1;
                            background-color: rgba($dark, 0.6);
                        }
                    }
                }
            }

            ul.slick-dots {
                position: absolute;
                z-index: 999;
                left: 50%;
                transform: translateX(-50%);
                bottom: 150px;
                width: 100%;
                display: flex !important;
                justify-content: flex-start;
                width: 100%;
                padding-left: 40px;
                padding-right: 40px;
                max-width: 1320px;

                @media only screen and (max-width:$laptop) {
                    padding-left: 30px;
                    padding-right: 30px;
                }

                @media only screen and (max-width:$tab) {
                    padding-left: 20px;
                    padding-right: 20px;
                }

                @media only screen and (max-width:$mobile) {
                    padding-left: 15px;
                    padding-right: 15px;
                }

                @media only screen and (max-width:1350px) {
                    bottom: 80px;
                }

                @media only screen and (max-height:800px) {
                    bottom: 60px;
                }

                li {

                    button {
                        height: 10px;
                        width: 10px;
                        background-color: rgba($white, 0.4);
                        border-radius: 50%;

                        &::before {
                            display: none;
                        }
                    }

                    &.slick-active {
                        button {
                            background-color: $white;
                        }
                    }
                }

            }
        }

        .slide_content {
            z-index: 9999;
            width: 100%;
            position: absolute;
            top: 50%;
            left: 0px;
            transform: translateY(-70%);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: 0px;
            margin: 0px;

            .content_box {
                margin-left: auto;
                margin-right: auto;
                padding: 20px 40px;
                max-width: 1320px;
                width: 100%;

                @media only screen and (max-width:$tab) {
                    padding: 20px 20px;
                }

                h1 {
                    max-width: 900px;
                    width: 100%;
                    text-transform: capitalize;
                    color: $white;
                    margin-bottom: 30px;

                    &.third {
                        max-width: 1300px;
                        width: 100%;
                    }

                    @media only screen and (max-width:$tab) {
                        margin-top: 50px;
                    }

                    @media only screen and (max-width:$mobile) {
                        margin-top: 0px;
                        @include font('GildaDisplay-Regular', 32px, 38px, 500, $white);
                    }

                    &.out {
                        transform: translateX(150px);
                        opacity: 0;
                    }
                }

                p {
                    color: $white;
                    max-width: 700px;
                    width: 100%;
                    margin: 25px 0px;
                    transition: all 0.6s ease-in-out;

                    &.out {
                        transform: translateX(150px);
                        opacity: 0;
                    }
                }

            }
        }
    }

    button.slick-arrow {
        display: none !important;
    }
}