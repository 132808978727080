@import "../../scss/typography";
@import "../../scss/variables";

section.mecred_privacy_title{
    background-color:$bgLight;
    .content_wrap{
        .bred_crum{
            padding-top:56px;
            padding-bottom:56px;
            h2{
                max-width:600px;
                width:100%;
            }
        }
    }
}

section.mecred_privacy_content{
    .content_wrap{
        padding-top:40px;

        h3{
            margin-bottom:24px;
        }
        p{
            margin-bottom:16px;
            @include font('SctoGroteskA-Regular', 18px, 27px, 400, $primary);
        }
        a{
            text-decoration: none;
            color:$primary;
        }
    }
}


