@import "../../../scss/typography";
@import "../../../scss/variables";

section.mecred_landing {
    background-color: $white;
    overflow: hidden;

    .content_wrap {
        display: grid;
        grid-template-columns: 1fr 0.9fr;
        gap: 80px;
        margin-top:48px;

        @media only screen and (max-width:$tab) {
            grid-template-columns: 1fr;
            gap: 25px;
        }

        .text_wrap {
            p {
                color: $primary;
                margin-top: 15px;
                text-align: justify;
            }

            .btn_wraper {
                margin-top: 25px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 20px;
                flex-wrap: wrap;
            }
            h3{
                font-size:48px;
                line-height: 56px;
            }
        }

        .img_wrap {
           display:flex;
           justify-content: center;
           align-items: flex-start;

            img {
                width: 100%;
                object-fit:contain;
                border-radius: 4px;  

            }
        }
    }
}


section.img_info{
    p.spread{
        text-align: justify;
    }
    margin-top:32px;
    img{
        width:100%;        
    }
    ul{
        list-style-type: disc;
        color:$primary;
        margin-top:16px;
    }
}