    @import "../../scss/typography";
    @import "../../scss/variables";


    .content_wrap {
        .plus_wrap {
            display: flex;
            justify-content: center;
            padding-bottom: 60px;
            padding-top: 60px;
            border-left:3px solid rgba($primary, 0.5);
            border-right:3px solid rgba($primary, 0.5);
        }
    }