@import "../../scss/typography";
@import "../../scss/variables";

section.contact_us {
    background-color: $primary;

    .content_wrap {
        .contact_title{
            display:none;
            @media only screen and (max-width:$tab){
                display:block;
                padding-top:32px;
            }
        }
        h1 {
            color: $white;
        }
        .bi_grid{
            
            display: grid;
            grid-template-columns: 1fr 0.6fr;
            gap:30px;
            margin-top:60px;
            @media only screen and (max-width:$laptop) {
                margin-top: 60px;
            }

            @media only screen and (max-width:$tab) {
                margin-top: 40px;
                grid-template-columns: 1fr;
                gap:50px;
            }
            h5{
                color:$white;
            }
        

        .form_wrap {
            background-color: $white;
            max-width: 650px;
            width: 100%;
            border-radius:10px;
            position: relative;
            overflow: hidden;
            border:1px solid $secondary;
      

            form {
                background-color: $bgLight;
                width: 100%;
                padding: 40px;
                display: flex;
                flex-direction: column;
                

                @media only screen and (max-width:$mobile) {
                    padding:16px;
                }

                h2 {
                    @include font('SctoGroteskA-Regular', 40px, 44px, 600, $primary);
                    margin-bottom: 30px;

                    @media only screen and (max-width:$tab) {
                        @include font('SctoGroteskA-Regular', 25px, 29px, 600, $primary);
                        margin-bottom: 20px;
                    }
                }
                p.error{
                    @include font('GildaDisplay-Regular', 14px, 18px, 600, red);
                    margin-top: 4px;
                }
                input,
                textarea {
                    padding: 12px 16px;
                    border-radius:8px;
                    background-color: $white;
                    border:1px solid $secondary;
                    margin-top:16px;
                    @include font('GildaDisplay-Regular', 16px, 20px, 600, $primary);

                    &:focus {
                        outline: none;
                    }

                }

                textarea {
                    min-height: 246px;
                }

                button {
                    margin-top: 16px;
                    display: inline-block;
                    width:150px;
                    &.hide{
                        opacity:0.6;
                        pointer-events:none;
                    }
                }
            }

            .form_success {                
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0px;
                left: 0px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 15px;
                background-color: rgba($primary, 0.2);
                backdrop-filter: blur(5px);
                padding: 30px;                
                transform-origin:top;  
                z-index:999;
                h3 {
                    text-align: center;
                    color: $primary;
                    &.error{
                        color:red;
                    }
                    
                }

                p.t-20{
                    text-align: center;                    
                    color: $primary;
                    font-weight: 600;
                    max-width: 400px;
                    width: 100%;
                    margin:0px auto;
                }              

                &.open {
                    transform: scaleY(1);
                }

                &.close {
                    transform: scaleY(0);
                }

               
            }
        }

        .address_side{
            .address_grid{
                display:grid;
                grid-template-columns: 1fr;
                gap:15px;
            }
            h3{
                color:$white;
                margin-bottom:10px;              
            }
            h5{
                margin-bottom:0px;
                &.mt{
                    margin-top:15px;
                }
            }
            p{
                color:$white;
            }
            a{
                color:$white;
                margin:0px;
                text-decoration:none;
                transition: all 0.3s ease-in-out;
                &:hover{
                    color:$secondary;
                }
            }
        }
        .info_wrap{
            display:flex;
            justify-content: flex-start;
            align-items: center;
            gap:8px;
            h5{
                margin:0px;
            }
            a{
                margin:0px;
            }
        }
    }
    }
}