@import "../../scss/typography";
@import "../../scss/variables";

section.book_demo_landing {
    background-color: $primary;
    position: relative;
    z-index: 2;

    .content_wrap {        
        padding-bottom: 200px;
        
    }
}



section.book_Demo_form {

    .content_wrap {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;

        @media only screen and (max-width:$tab) {
            grid-template-columns: 1fr;
        }

        .text {
            padding-right: 80px;
            p{
                margin-bottom:15px;
            }

            @media only screen and (max-width:$tab) {
                order: 1;
            }
        }

        .form_box {
            position: relative;
            height: 300px;

            @media only screen and (max-width:$tab) {
                height: 500px;
            }

            .form_wraper {
                background-color: $white;
                max-width: 650px;
                width: 100%;
                border-radius: 10px;
                position: relative;
                overflow: hidden;
                border: 1px solid $secondary;
                position: absolute;
                top: -420px;
                left: 0px;
                width: 100%;
                z-index: 99;

                @media only screen and (max-width:$tab) {
                    top: -220px;
                }

                form {
                    background-color: $white;
                    width: 100%;
                    padding: 40px;
                    display: flex;
                    flex-direction: column;
                    gap: 15px;

                    @media only screen and (max-width:$mobile) {
                        padding: 20px;
                    }

                    h2 {
                        @include font('SctoGroteskA-Regular', 40px, 44px, 600, $primary);
                        margin-bottom: 30px;

                        @media only screen and (max-width:$tab) {
                            @include font('SctoGroteskA-Regular', 25px, 29px, 600, $primary);
                            margin-bottom: 20px;
                        }
                    }

                    input,
                    textarea {
                        padding: 12px 16px;
                        border-radius: 8px;
                        background-color: $white;
                        border: 1px solid $secondary;
                        @include font('SctoGroteskA-Regular', 16px, 20px, 600, $primary);

                        &:focus {
                            outline: none;
                        }

                    }

                    textarea {
                        min-height: 246px;
                    }

                    button {
                        margin-top: 10px;
                        display: inline-block;
                        width: 150px;
                    }
                }

                .form_success {

                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    gap: 15px;
                    background-color: rgba($primary, 0.2);
                    backdrop-filter: blur(5px);
                    padding: 30px;
                    border-radius: 20px;
                    transform-origin: top center;
                    transition: all 0.3s ease-in-out;

                    &.open {
                        transform: scaleY(1);
                    }

                    &.close {
                        transform: scaleY(0);
                    }

                    h3 {
                        text-align: center;
                        color: $primary;
                    }

                    p {
                        text-align: center;
                        color: $primary;
                        font-weight: 600;
                        max-width: 400px;
                        width: 100%;
                    }
                }
            }
        }
    }
}