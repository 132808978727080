@import "../../../scss/typography";
@import "../../../scss/variables";

section.multi_grid_sec {
    .content_wrap {
        .title {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;
            margin-bottom: 60px;

            @media only screen and (max-widthL:$tab) {
                margin-bottom: 40px;
            }

            h2 {
                max-width: 850px;
                width: 100%;
                text-align: center;
            }

            p {
                text-align: center;
                max-width: 850px;
                width: 100%;
            }
        }

        .multi_grid {
            display: grid;
            grid-template-columns:1fr 1fr;
            gap: 30px;
            @media only screen and (max-width:$tab){
                grid-template-columns:1fr;
            }
            .grid_card{
                padding:30px;
                border-radius:20px;
                min-height:300px;
                background-color: $primary;
                @media only screen and (max-width:$tab){
                    min-height:250px;
                }
                h3{
                    margin-bottom:20px;
                    color:$white;
                }
                 p{
                    color:$white;
                }   
                
                ul{
                    color:$white;
                }

            }
        }
    }
}