@import "../../../scss/typography";
@import "../../../scss/variables";

section.accord_sec {
    .content_wrap {
        .acc_grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 42px;

            @media only screen and (max-width:850px) {
                grid-template-columns: 1fr;
                gap: 30px;
            }

            h3 {
                text-align: center;
            }

            .accordion_side {
                padding: 32px;
                border-radius: 24px;
                background-color: $bgLight;
                align-self: flex-start;

                @media only screen and (max-width:$mobile) {
                    padding: 16px;
                }

                .accordion {
                    background-color: transparent;
                    border-style: none;

                    .accordion-item {
                        background-color: transparent;
                        border-style: none;

                        .accordion-header {
                            background-color: transparent;

                            button {
                                padding: 15px 0px;
                                background-color: transparent;
                                border-bottom: 1px solid rgba($primary, 0.3);
                                @include font('SpaceGrotesk-Medium', 26px, 30px, 400, $primary);

                                &:focus {
                                    outline: none;
                                    box-shadow: none
                                }
                            }
                        }

                        .accordion-collapse {
                            .accordion-body {
                                p {
                                    font-weight: 600;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}