@import "../../../scss/typography";
@import "../../../scss/variables";


section.build_trade {
    background-color: $white;

    .content_wrap {
        .trade_grid {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 30px;

            @media only screen and (max-width:$tab) {
                grid-template-columns: 1fr;
                gap: 20px;
            }

            @media only screen and (max-width:$mobile) {
                gap: 15px;
            }

            .info_card {
                padding: 30px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 10px;
                border: 1px solid rgba($primary, 0.7);
                border-radius: 20px;
                background-color: $primary;
                transition: all 0.3s ease-in-out;

                @media only screen and (max-width:$tab) {
                    padding: 15px;
                }

                img{
                    color:$secondary;
                    max-width:60px;
                    max-height:60px;
                    object-fit:contain;                   
                    transition:all 0.6s ease-in-out;
                    @media only screen and (max-width:$tab){
                     max-width:40px;
                    max-height:40px;
                    }
                 }

                h3 {
                    color: $white;
                }

                p {
                    color: $white;
                }

                img {
                    width: 60px;
                }

                &:hover {
                    img {
                        transform: rotateY(180deg);
                    }
                }

            }
        }
    }
}