@import "../../scss/typography";
@import "../../scss/variables";


section.sub_company_header{
    .content_wrap{
        
.company_logo_wrap {  
    padding-top:15px;              
    a{                    
        img {
            object-fit: contain;                    
            max-height:150px;
            object-fit: contain;
        }
    }   
}

}
}