@import "../../../scss/typography";
@import "../../../scss/variables";

section.solution_landing {
    background-color: $primary;
    .content_wrap {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 80px;

        @media only screen and (max-width:$tab){
            grid-template-columns: 1fr;
            gap:30px;
        }

        .text_wrap {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            h1.main_title{
                @include font('SpaceGrotesk-Medium', 60px, 63px, 500, $primary);
                margin-bottom:25px;
                @media only screen and (max-width:$laptop){
                    @include font('SpaceGrotesk-Medium', 50px, 55px, 500, $primary);
                }
                @media only screen and (max-width:$tab){
                    @include font('SpaceGrotesk-Medium', 40px, 45px, 500, $primary);
                }
            }
            p{
                color:$white;
                margin-top:15px;
            }
            button{
                margin-top:40px;
            }
        }

        .img_wrap {
            img{
                max-width:100%;
            }
        }
    }
}