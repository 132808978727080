@import "../../scss/typography";
@import "../../scss/variables";

button.primary_btn{
    padding:15px 35px;
    border-radius: 1000px;
    @include font('GildaDisplay-Regular', 14px, 16px, 600, $primary);
    text-transform: uppercase;
    transition: all 0.6s ease-in-out;
    letter-spacing: 1px;
    border-style: none;
    border:1px solid $primary;
    &.dark{
        background:$primary;
        color:$white;        
    }

    &.light{        
        background-color:$white ;
        color:$primary;
    }

    &:hover{
        &.dark{
            background-color:$white;
            color:$primary;     
        }
        &.light{
            background:$primary;
            color:$white;   
            border:1px solid $white;     
        }
    }

    &.out{
        transform: translateX(150px);
        opacity:0;
    }

}