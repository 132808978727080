@import "../../scss/variables";
@import "../../scss/mixin";


section.mecred_soon{
    .content_wrap{
        min-height:100vh;
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        h2{
            text-align: center;
            max-width:800px; 
            @include font('GildaDisplay-Regular', 40px, 46px, 500, $primary);
            @media only screen and (max-width:$tab){
                @include font('GildaDisplay-Regular', 32px, 36px, 500, $primary);
            } 
            @media only screen and (max-width:$mobile){
                @include font('GildaDisplay-Regular', 26px, 30px, 500, $primary);
            }           
        }
    }
}