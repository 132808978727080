@import "../../scss/typography";
@import "../../scss/variables";

footer.company {
    background-color: $bgLight;

    section.subcompany_footer {
        .content_wrap {
            .footer_grid {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                gap: 20px;
                padding-bottom: 50px;
                @media only screen and (max-width:$tab){
                    grid-template-columns: 1fr;
                }
            }

            .logo_wrap {

                img {
                    max-height: 100px;
                }
            }

            .col_sec {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 5px;

                h3 {
                    color: $primary;
                    margin-bottom: 16px;
                    @include font('GildaDisplay-Regular', 20px, 28px, 600, $primary);
                    @media only screen and (max-width:$tab){
                        margin-bottom:12px;
                    }
                }

                a {
                    @include font('SctoGroteskA-Regular', 20px, 24px, 400, $primary);
                    letter-spacing: 1px;
                    text-decoration: none;
                    cursor: pointer;
                    transition: all 0.3s ease-in-out;

                    &:hover,
                    &.active {
                        color: $primary;
                    }
                }

                p {
                    @include font('SctoGroteskA-Regular', 20px, 24px, 400, $primary);
                }

                .info {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 6px;
                    margin-top: 8px;
                    .in_line{
                        display:flex;
                        justify-content: flex-start;
                        align-items: center;
                    }

                    svg {
                        font-size: 22px;
                        color: $primary;
                    }
                }
            }

            p.desclaim {
                color: $primary;
                padding-bottom: 25px;

                a {
                    color: $secondary;
                    font-size: 20px;
                    
                }
            }
        }
    }
}