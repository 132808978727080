@import "../../../scss/typography";
@import "../../../scss/variables";

section.commpany_detail_landing {
    background-color: $white;
    overflow: hidden;

    .content_wrap {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 80px;
        margin-top:48px;

        @media only screen and (max-width:$tab) {
            grid-template-columns: 1fr;
            gap: 25px;
        }

        .text_wrap {
            p {
                color: $primary;
                margin-top: 15px;
                text-align: justify;
            }

            .btn_wraper {
                margin-top: 25px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 20px;
                flex-wrap: wrap;
            }
        }

        .img_wrap {
            display: grid;
            place-items: center;

            img {
                width: 100%;
                object-fit: contain;
                border-radius: 4px;                
            }
        }
    }
}