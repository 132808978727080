@import "../../../scss/variables";


section.about_us {
    .content_wrap {
        p {
            text-align: center;
            margin-top: 16px;
            text-align: justify;
            font-weight: 400;

            a {
                color: $secondary;
                text-decoration: none;
                transition: all 0.3s ease-in-out;
                position: relative;

                &::after {
                    content: "";
                    position: absolute;
                    bottom: 0px;
                    left: 0px;
                    height: 2px;
                    width: 0%;
                    background-color: $secondary;
                    transition: all 0.3s ease-in-out;
                }

                &:hover {
                    &::after {
                        width: 100%;
                    }
                }
            }
        }
    }
}