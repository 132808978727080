@import "./variables";
@import "./mixin";


@font-face {
    font-family:'GildaDisplay-Regular';
    src: url('../assets/fonts/GildaDisplay-Regular.woff') format('woff');
    font-style: normal;
    font-display: swap;
}

p {
    margin: 0px;
    @include font('GildaDisplay-Regular', 20px, 28px, 400, $primary);
    &.dark{
        @include font('GildaDisplay-Regular', 20px, 28px, 400, $dark);
    }
    &.t-18{
        @include font('GildaDisplay-Regular', 18px, 27px, 400, $primary);
    }
    &.t-20{
        @include font('GildaDisplay-Regular', 20px, 24px, 5 00 , $primary);
    }
}

h1{
    margin: 0px;
    @include font('GildaDisplay-Regular', 80px, 84px, 500, $primary);
    
    &.gradient{
        background: -webkit-linear-gradient( 0deg, $primary 60%, $secondary 80%, $primary 100%);
        background-size:200%;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        animation:textAnime 6s infinite linear;

        &.dark{
            background: -webkit-linear-gradient( 0deg, $white 60%, $secondary 80%, $white 100%);         
            background-size:200%;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            animation:textAnime 6s infinite linear;
        }    
    }
   
}

h2 {
    margin: 0px;
    @include font('GildaDisplay-Regular', 60px, 63px, 500, $primary);
    &.gradient{
        background: -webkit-linear-gradient( 0deg, $primary 60%, $secondary 80%, $primary 100%);
        background-size:200%;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        animation:textAnime 6s infinite linear;

        &.dark{
            background: -webkit-linear-gradient( 0deg, $white 60%, $secondary 80%, $white 100%);         
            background-size:200%;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            animation:textAnime 6s infinite linear;
        }    
    }

}

h3 {
    margin: 0px;
    @include font('GildaDisplay-Regular', 32px, 39px, 500, $primary);
    &.gradient{
        background: -webkit-linear-gradient( 0deg, $primary 60%, $secondary 80%, $primary 100%);
        background-size:200%;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        animation:textAnime 6s infinite linear;

        &.dark{
            background: -webkit-linear-gradient( 0deg, $white 60%, $secondary 80%, $white 100%);         
            background-size:200%;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            animation:textAnime 6s infinite linear;
        }    
    }
}
h4{
    @include font('GildaDisplay-Regular', 22px, 26px, 600, $primary); 
}

@media only screen and (max-width:$laptop){
    p{
        
        &.t-20{
            @include font('SctoGroteskA-Regular', 16px, 20px, 500 , $primary);
        }
    }
   h1{
    @include font('GildaDisplay-Regular', 64px, 68px, 500, $primary);
   }
   h2{
    @include font('GildaDisplay-Regular', 50px, 55px, 500, $primary);
   }
}

@media only screen and (max-width:1000px){
  h1{
    @include font('GildaDisplay-Regular', 48px, 54px, 500, $primary);
  }
}

@media only screen and (max-width:$tab){
    p{
        @include font('SctoGroteskA-Regular', 18px, 24px, 400, $primary);
    }
    h2{
        @include font('GildaDisplay-Regular', 40px, 45px, 500, $primary);
       }
}


@keyframes textAnime {
    to{
        background-position: 200%;
    }
}