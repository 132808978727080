@import "../../../scss/typography";
@import "../../../scss/variables";

section.request_demo_sec {
    .content_wrap {
        padding: 64px 80px;
        border-radius: 20px;
        background-color: $primary;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap:30px;

        @media only screen and (max-width:$desktop){
            grid-template-columns: 1fr 0.5fr;
        }
        @media only screen and (max-width:$tab){
            grid-template-columns: 1fr;
            padding:48px;
        }
        @media only screen and (max-width:$mobile){
            grid-template-columns: 1fr;
            padding:32px;
        }

        h3 {
            @include font('SpaceGrotesk-Medium', 40px, 46px, 500, $primary);
            @media only screen and (max-width:$tab){
                @include font('SpaceGrotesk-Medium', 36px, 40px, 500, $primary);   
            }

            &.gradient {
                background: -webkit-linear-gradient(0deg, $primary 60%, $secondary 80%, $primary 100%);
                background-size: 200%;
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                animation: textAnime 6s infinite linear;

                &.dark {
                    background: -webkit-linear-gradient(0deg, $white 60%, $secondary 80%, $white 100%);
                    background-size: 200%;
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    animation: textAnime 6s infinite linear;
                }
            }
        }
        .right{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            @media only screen and (max-width:$tab){
                justify-content: flex-start;
            }
        }
    }
}