// Colors

// $primary:#1a78ee;

$primary:#041d2c;
$secondary:#1aa8d4;

$square:#1aa8d4;

$bgLight:rgba($primary, 0.1);

$white:#ffffff;
$dark:#131313;

// Break Points

$bigDesktop:1500px;
$desktop:1250px;
$laptop: 992px;
$tab: 767px;
$mobile: 479px;