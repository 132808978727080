@import "../../scss/typography";
@import "../../scss/variables";

section.privacy_page_landing {
    background-color: $primary;
    padding-top: 80px;
    padding-bottom: 80px;

    @media only screen and (max-width:$tab) {
        padding-top: 60px;
        padding-bottom: 60px;
    }

}

section.privacy_page_content {
    .content_wrap {
        padding-top: 48px;

        p {
            @include font('SctoGroteskA-Regular', 18px, 24px, 500, $primary);
        }

        h2.gradient {
            font-size: 40px;
            line-height: 48px;
            margin-top: 30px;

            @media only screen and (max-width:$tab) {
                font-size: 32px;
                line-height: 40px;
            }
        }

        p {
            margin-top: 15px;
        }

        ul {
            color: $primary;
            margin-top: 15px;
            padding-left: 25px;

            li {
                p {
                    margin: 0px;
                }
            }
        }
    }
}