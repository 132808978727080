@import "../../../scss/typography";
@import "../../../scss/variables";

.logo_slider {
    background-color: $white;

    .logo_title {
        margin-bottom:70px;
        h2 {
            text-align: center;
        }
        @media only screen and (max-width:$tab){
            margin-bottom:48px;
        }
    }
    .slider_wrap {
        @media only screen and (max-width:$tab) {
            margin-top: 60px;
        }

        .slide_img_wraper {
            .img_wrap {
                height: 100px;
                padding: 15px;
                display: grid;
                place-items: center;

                img {
                    max-width: 90%;
                    max-height: 90%;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }

        .slick-slider {
            button.slick-arrow {
                display: none !important;
            }
        }
    }
}