@import "../../../scss/typography";
@import "../../../scss/variables";


section.our_solution {
    background-color: $white;

    .content_wrap {
        .bi_grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;

            @media only screen and (max-width:$tab) {
                grid-template-columns: 1fr;
                gap: 20px;
            }

            @media only screen and (max-width:$mobile) {
                gap: 15px;
            }

            .info_card {
                padding: 50px;
                border-radius: 20px;
                display: flex;
                flex-direction: column;
                gap: 15px;
                align-items: flex-start;
                background: linear-gradient(45deg, $primary 0%, $primary 30%, $primary 70%, $primary 100%);

                transition: all 0.4s ease-in-out;

                @media only screen and (max-width:$tab) {
                    padding: 30px;
                }

                @media only screen and (max-width:$tab) {
                    padding: 15px;
                }

                &::after {
                    content: "";
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    width: 100%;
                    height: 100%;
                    z-index: 0;
                    transition: all 0.4s ease-in-out;
                }



                img {
                    max-width: 100%;
                    position: relative;
                    z-index: 1;
                    transition: all 0.3s ease-in-out;
                }

                h3 {
                    position: relative;
                    z-index: 1;
                    color:$white;                  
                }

                &:hover {
                    background: linear-gradient(45deg, $secondary 0%, $primary 30%, $primary 70%, $secondary 100%);

                    &::after {
                        background-position: left;
                    }
                }

                p {
                    position: relative;
                    z-index: 1;
                    color: $white;
                }

                button {
                    position: relative;
                    z-index: 1;
                    margin-top:auto;
                }

            }
        }
    }
}