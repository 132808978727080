@import "../../../scss/typography";
@import "../../../scss/variables";

section.info_lr {
    overflow:hidden;
transition:all 0.8s ease-in-out;
    .content_wrap {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;

        @media only screen and (max-width:$tab) {
            grid-template-columns: 1fr;
        }

        .img_wrap {
            img {
                max-width: 100%;
            }
        }

        .text_wrap {
            h2 {
                margin-bottom: 30px;
            }

            p {
                margin-bottom: 15px;
            }
        }

        &.left {
            .img_wrap {
                padding-right: 80px;

                @media only screen and (max-width:$laptop) {
                    padding-right: 40px;
                }

                @media only screen and (max-width:$tab) {
                    padding-right: 0px;
                }
            }
        }

        &.right {
            .img_wrap {
                order: 2;
                padding-left: 80px;

                @media only screen and (max-width:$laptop) {
                    padding-left: 40px;
                }

                @media only screen and (max-width:$tab) {
                    padding-left: 0px;
                    order:0;
                }
            }
        }
    }

    &.dark{
        background-color: $primary;
        .content_wrap{
            .text_wrap{
                p{
                    color:$white;
                }
            }
        }
    }
    ul{
        color:$primary;
        li{
            color:$primary;
        }
    }
}