@import "../../scss/typography";
@import "../../scss/variables";

section.error {
    .content_wrap {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        h1{
            font-size:250px;
            line-height:265px;
            text-align: center;
            @media only screen and (max-width:$tab){
                font-size:150px;
                line-height:160px;
            }
            @media only screen and (max-width:$mobile){
                font-size:100px;
                line-height:110px;
            }
                }
        p{
            font-weight:600;
            margin:20px 0px;
            text-align: center;
        }
        h2{
            margin:10px 0px;
            text-align: center;
        }

    }
}