@import "../../scss/typography";
@import "../../scss/variables";


.centered_title{
display:flex;
flex-direction: column;
align-items: center;
padding-bottom:64px;
h2{
    text-align: center;
}
p{
    text-align: center;
    font-weight:600;
    margin-top:12px;
}
}